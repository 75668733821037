<template>
<div class="my-4">
      <div style="font-family: Calibri; margin-bottom: 50px;">
        <h1 style="margin-bottom: 20px; margin-left:0px; margin-right: 0px; font-size: 24px; color: #00338D">{{article.title}}</h1>
        <div class="mb-2" v-if="options.published"><strong>Published: </strong><span>{{ moment(article.approved_at).format('YYYY-MM-DD') }}</span></div>
        <div class="mb-2" v-if="options.events && article.events.length > 0">
          <strong>Events: </strong>
          <li class="ml-4" v-for="event in article.events" :key="'event' + event.id">
            <strong>{{ moment(event.date).format('YYYY-MM-DD') }}</strong> - {{event.name}},
          </li>
        </div>
        <div class="mb-2" v-if="options.regtopics && article.regtopics.length > 0">
          <strong>Topics: </strong>
          <span v-for="topic in article.regtopics" :key="'topic' + topic.id">
            {{topic.name}},
          </span>
        </div>
        <div class="mb-2" v-if="options.risks && article.risks.length > 0">
          <strong>Risks: </strong><span v-for="risk in article.risks" :key="'risk' + risk.id">
            {{risk.name}},
          </span>
        </div>
        <div class="mb-2" v-if="options.tags && article.tags.length > 0">
          <strong>Tags: </strong>
          <span v-for="tag in article.tags" :key="'tag' + tag.id">
            {{ tag.name }},
          </span>
        </div>
        <div class="mb-3" v-if="options.news && article.publications.length > 0">
          <strong>News: </strong>
          <span v-for="publication in article.publications" :key="'publication' + publication.id">
            {{publication.name}},
          </span>
        </div>
        <div v-html="article.body"></div>
      </div>
</div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    propItem: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  },
  computed: {
    article: function () {
      let result
      if (this.options.para === true) {
        result = Object.assign({}, this.propItem)
        const bodyOriginal = this.propItem.body
        const chunks = bodyOriginal.split('</p>')
        const body = chunks[0] + '</p>'
        result.body = body
      } else {
        result = this.propItem
      }
      return result
    }
  },
  data () {
    return {
      moment: moment
    }
  }
}
</script>

<style>
</style>
