<template>
<div>
  <b-container fluid>
    <b-row class="row text-white bg-info">
      <b-col align-self="center" cols="2" class=" cols text-center" align-v="center">
        <h1 justify="center" align="center" class="kstyle title justify-content-md-center">Insights report</h1>
      </b-col>
      <b-col cols="8" align-self="center" align-v="center" class="text-center justify-content-md-center">
      </b-col>
    </b-row>
    <b-row class="my-2">
      <b-col class="text-center">
        <b-form inline class="mb-4">
          report template:
          <b-form-select class="mx-2" v-model="reportSelected" :options="reportOptions"></b-form-select>
          <b-button variant="outline-primary" class="mr-2" v-b-toggle.sidebar-1>settings</b-button>
          <b-button variant="outline-primary" @click="generateReport">download report</b-button>
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div v-if="articles.length > 0" id="exportElement">
          <div v-if="reportSelected === 'table-topics'">
            <div v-for="topic in articlesByTopic" :key="'topic-heading'+topic.id">
              <h2>{{topic.name}}</h2>
                <table>
                  <tr style="background-color: #00338D; color: white; font-weight: 700; padding-bottom: 10px; padding-top: 10px;">
                    <td style="border: 1px solid #00338D; color: white; font-weight: 700; border-collapse: collapse; padding: 5px;"><strong>Insight</strong></td>
                    <td style="border: 1px solid #00338D; color: white; font-weight: 700; border-collapse: collapse; padding: 5px;"><strong>Details</strong></td>
                    <td style="border: 1px solid #00338D; color: white; font-weight: 700; border-collapse: collapse; padding: 5px;"><strong>Type</strong></td>
                    <td style="border: 1px solid #00338D; color: white; font-weight: 700; border-collapse: collapse; padding: 5px;"><strong>Timelines</strong></td>
                  </tr>
                  <tr v-for="row in topic.articles" :key="'article'+row.id">
                    <td>{{row.title}}<br /><a v-if="options.includeLinks" :href="'https://kpmgregulatoryhorizon.com/article/' + row.id">link</a></td>
                    <td>{{firstPara(row.body)}}</td>
                    <td>
                      <div v-if="row.tagsCombined.length > 0">
                        <div v-for="item in row.tagsCombined" :key="'tag' + item.id">
                          {{item.name}}
                          </div>
                      </div>
                    </td>
                    <td>
                      <div v-if="row.eventsCombined.length > 0">
                        <div v-for="event in row.eventsCombined" :key="'event' + event.id">
                          {{moment(event.date).format('YYYY-MM-DD')}}: {{event.name}}<br />
                          {{event.comments}}<br /><br />
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
            </div>
          </div>
          <div v-if="reportSelected === 'table'">
            <table>
              <tr>
                <td><strong>Insight</strong></td>
                <td><strong>Topic</strong></td>
                <td><strong>Details</strong></td>
                <td><strong>Type</strong></td>
                <td><strong>Timelines</strong></td>
              </tr>
              <tr v-for="row in articles" :key="'article'+row.id">
                <td>{{row.title}}<br v-if="options.includeLinks" /><a v-if="options.includeLinks" :href="'https://kpmgregulatoryhorizon.com/article/' + row.id">link</a></td>
                <td>
                  <div v-if="row.regtopicsCombined.length > 0">
                    <div v-for="item in row.regtopicsCombined" :key="'regtopic' + item.id">
                      {{item.name}}
                      </div>
                  </div>
                </td>
                <td>{{firstPara(row.body)}}</td>
                <td>
                  <div v-if="row.tagsCombined.length > 0">
                    <div v-for="item in row.tagsCombined" :key="'tag' + item.id">
                      {{item.name}}
                      </div>
                  </div>
                </td>
                <td>
                  <div v-if="row.eventsCombined.length > 0">
                    <div v-for="event in row.eventsCombined" :key="'event' + event.id">
                      {{moment(event.date).format('YYYY-MM-DD')}}: {{event.name}}<br />
                      {{event.comments}}<br /><br />
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div v-if="reportSelected === 'long'">
            <div class="mb-3" v-for="item in articles" :key="'publication-' + item.id">
              <publication-report
                :propItem="item"
                :options="options"
              />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
  <b-sidebar id="sidebar-1" title="Report settings" shadow>
    <div class="px-3 py-2">
      <b-form-checkbox v-model="options.published" :value="true" :unchecked-value="false">published</b-form-checkbox>
      <b-form-checkbox v-model="options.events" :value="true" :unchecked-value="false">events</b-form-checkbox>
      <b-form-checkbox v-model="options.news" :value="true" :unchecked-value="false">news</b-form-checkbox>
      <b-form-checkbox v-model="options.regtopics" :value="true" :unchecked-value="false">regtopics</b-form-checkbox>
      <b-form-checkbox v-model="options.risks" :value="true" :unchecked-value="false">risks</b-form-checkbox>
      <b-form-checkbox v-model="options.tags" :value="true" :unchecked-value="false">tags</b-form-checkbox>
      <b-form-checkbox v-model="options.para" :value="true" :unchecked-value="false">first para only</b-form-checkbox>
      <b-form-checkbox v-model="options.includeLinks" :value="true" :unchecked-value="false">include links</b-form-checkbox>
    </div>
  </b-sidebar>
</div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import PublicationReport from '@/components/ArticleReport'
import striptags from 'striptags'

export default {
  components: {
    PublicationReport
  },
  computed: {
    articlesByTopic: function () {
      let topics = []
      this.articles.forEach(x => {
        topics = topics.concat(x.regtopicsCombined)
      })
      topics = _.sortBy(_.uniqBy(topics, 'id'), ['name'])
      _.each(topics, topic => {
        topic.articles = _.filter(this.articles, article => {
          return _.find(article.regtopicsCombined, ['id', topic.id])
        })
      })
      return topics
    },
    articles: {
      get () {
        return this.$store.state.articles
      },
      set (payload) {
        this.$store.commit('setArticles', payload)
      }
    }
  },
  data () {
    return {
      firstPara: function (text) {
        const chunks = text.split('</p>')
        const result = striptags(chunks[0] + '</p>')
        return result.replace(/&nbsp;/g, ' ')
      },
      options: {
        events: true,
        includeLinks: true,
        news: true,
        para: false,
        published: true,
        regtopics: true,
        risks: true,
        tags: true
      },
      moment: moment,
      reportOptions: ['long', 'table', 'table-topics'],
      reportSelected: 'long'
    }
  },
  created: async function () {
    await this.$nextTick()
    if (this.articles.length > 0) {
      window.localStorage.setItem('frmCloudArticles', JSON.stringify(this.articles))
    } else {
      if (window.localStorage.getItem('frmCloudArticles') !== null) {
        this.articles = JSON.parse(window.localStorage.getItem('frmCloudArticles'))
      }
    }
    console.log(this.articles)
    console.log(this.articlesByTopic)
  },
  methods: {
    generateReport: function () {
      const start = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
      <head>
        <meta charset='utf-8'>
        <title>Export HTML To Doc</title>
        <style>
          body {
            font-family: arial;
          }
          h1, h2, h3, h4 {
            color: #00338D;
          }
          h4 {
            margin-top: 18px;
            margin-bottom: 6px;
          }
          table {
            border: 1px solid #00338D;
            border-collapse: collapse;
            font-size: 8px;
          }
          td {
            border: 1px solid #00338D;
            border-collapse: collapse;
            padding: 5px;
            vertical-align: top;
          }
          .mb-2 {
            margin-bottom: 6px;
          }
          .section {
            color: white;
            background-color: #00338D;
          }
        </style>
      </head>
      <body>`
      const end = "</body></html>"
      const html = start + document.getElementById('exportElement').innerHTML + end
      // const blob = new Blob(['\ufeff', html], { type: 'application/msword' })
      const url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html)
      const filename = 'insights.doc'
      const link = document.createElement('a')
      link.href = url
      link.download = filename
      link.click()
    }
  },
  watch: {
  }
}
</script>
<style>
</style>
